<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Industry List" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col> 
              <b-col cols="12" md="5">
                <Select
                  v-model="search.status"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Status"
                >
                  <Option :value="1"> Active </Option>
                  <Option :value="2"> Inactive </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search name..."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Name</th>
                  <th class="text-center">Status</th>
                  <th
                    class="text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(industry, index) in industries.data" :key="index">
                  <td class="align-middle text-center">
                    {{ industries.from + index }}
                  </td>
                  <td>
                    <b>{{ industry.name }}</b>
                  </td>

                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        industry.status == 1 ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ industry.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td
                    class="align-middle text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    <button
                      v-if="permission.edit"
                      class="btn btn-primary btn-sm"
                      @click="Edit(industry.id)"
                    >
                      <feather-icon icon="EditIcon" size="16" />
                    </button>

                    <button
                      v-if="permission.delete"
                      class="btn btn-danger btn-sm ml-1"
                      @click="Delete(industry.id)"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
                >Showing {{ industries.from }} to {{ industries.to }} of
                {{ industries.total }} entries</span
              >
              <pagination
                :data="industries"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card :title="(!form.id ? 'Create' : 'Update') + ' Industry'">
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <label> Industry Name </label>
                <Input
                  v-model="form.name"
                  placeholder="Enter Industry Name"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="name"></has-error>
              </b-col>
              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center mt-2"
                >
                  <b-form-checkbox
                    name="status"
                    style="margin-top: 5px"
                    v-model="form.status"
                    switch
                    inline
                  />
                  <div>
                    <b-button
                      variant="transparent"
                      class="border text-danger"
                      @click="Clear"
                    >
                      Clear
                    </b-button>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="form.busy"
                      class="ml-1"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
      industries: {},
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/industry${queryParams}`)
        .then((res) => {
          this.industries = res.data.industries;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.status = true;
    },

    Create() {
      this.form
        .post("/app/industry")
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update(id) {
      this.form
        .put("/app/update/industry/" + id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("/app/edit/industry/" + id)
        .then((res) => {
          this.form.id = res.data.industry.id;
          this.form.name = res.data.industry.name;
          this.form.status = res.data.industry.status == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/delete/industry/" + id)
            .then((res) => {
              this.s(res.data.message);
              if (this.industries.data.length == 1) {
                this.search.page = 1;
              }
              this.getResults();
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.industry;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
